<template>
  <div class="fill-height">
    <v-fade-transition mode="out-in">
      <div class="fill-height" :key="1" v-if="!isLoggedin">
        <AuthWindow @afterLogin="afterLogin"/>
      </div>
      <div v-else class="fill-height pb-15" :key="2">
        <NavbarPreorder/>
        <div class="preorderArea">
          <v-container>
            <v-row class="">
              <v-col cols="12">
                <v-fade-transition>
                  <CartComponent v-if="showCart && profile"/>
                  <template v-else>
                    <div class="fill-height text-center mt-10">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </template>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/
import NavbarPreorder from "@/components/Preorder/NavbarPreorder";
import LoginView from "@/views/panel/LoginView";
import AuthWindow from "@/components/Auth/AuthWindow";
import CartComponent from "@/components/Basket/CartComponent";
import EventsMixins from "@/mixins/EventsMixins";
import {mapGetters} from "vuex";

/**
 * <h1>Preorder Page</h1>  <p>this component contain <b>Cart Component </b> functionality</p>
 *
 * @vue-event  Mounted - if user is loggedin , call <b>addToCart</b> method to add the product to the cart that has this ID in the URL
 * @vue-event  Watch - if user isnt loggedin , first see login form to login and then get basket, this <b>Watch</b>  when user logged in , call <b>addToCart</b>
 *
 */
export default {
  components: {CartComponent, AuthWindow, LoginView, NavbarPreorder},
  mixins: [EventsMixins],

  mounted() {
    if (this.isLoggedin) {
      this.addToCart()
    }
  },
  data() {
    return {
      showCart: false,
    }
  },

  methods: {
    afterLogin() {

    },

    /**
     *
     * This is function for adds the product whose id is in the url to the cart.<br/>
     *
     * <b>Note:</b> this api call must have key <i>addone</i> with value <i>true</i> in request body
     *
     * */
    addToCart() {
      this.requesting('cart', 'addToCart', '', {
        addone: true,
        package_id: this.$route.params.orderId,
      }).then((resp) => {
        if (resp.data.hasOwnProperty('status')) {
          this.$emitEvent('notify', {title: this.$t('inBasketError'), color: 'red'})
        }
        this.sendAddToCartEvents(resp)
        this.replaceCart(resp.data[0])
        this.showCart = true
      })
    },
    replaceCart(cart) {
      let payload = {
        key: 'cart',
        data: [
          cart
        ]
      }
      this.$store.dispatch('setCart', payload)
      // this.prepareBasketProduct()
    },
  },

  computed: {
    ...mapGetters({
      'loading': 'getLoading',
      'profile': 'getProfile',
    })
  },
  watch: {
    'isLoggedin'(val) {
      if (val) {
        this.addToCart()
      }
      console.log(val, '21212112')
    }
  }

}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .preorderArea {
    margin-top: 100px !important;
  }
}

.preorderArea {
  margin-top: 150px;
}
</style>