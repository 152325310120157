<template>
  <div>
    <ContinueModalBasket :pre_session_counseling="pre_session_counseling"/>
    <modal-gift-code @afterSubmitCode="afterSubmitCode" :modal-gift-code="modalGiftCode"/>
    <v-fade-transition>
      <v-row v-if="cart">
        <v-col cols="12" md="8" lg="8">
          <div class="basketItems">
            <div class="titleBasket">
              {{ $t('basket.title') }}
            </div>
            <div class="divider"></div>
            <v-data-table
                v-if="products.length != 0 && currency"
                :headers="headers"
                disable-pagination
                hide-default-footer
                :items="products"
            >
              <template v-slot:item.title="{item}">
                <p style="font-size: 12px" class="mt-3" :id="item.item.id">
                  <template v-if="$vuetify.rtl">
                    {{ item.item.title }}
                  </template>
                  <template v-else>
                    {{ item.item.en_title }}
                  </template>
                </p>
              </template>
              <template v-slot:item.qty="{item}">
                <div class="qtyArea centering">
                  <div class="d-flex">
                    <div class="flex-grow-1" @click="increaseQuantity(item)" :id="`${item.item.id}_qty_increase`">
                      +
                    </div>
                    <div class="flex-grow-1" :id="`${item.item.id}_qty`">
                      {{ item.qty }}
                    </div>
                    <div class="flex-grow-1" @click="decreaseQuantity(item)" :id="`${item.item.id}_qty_decrease`">
                      -
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:item.amount="{item}">
                <p style="font-size: 12px" class="mt-3">
                  {{ withCurrency(item.item.pivot.price, currency, 'long') }}
                </p>
              </template>
              <template v-slot:item.total_amount="{item}">
                <p style="font-size: 12px" class="mt-3">
                  {{ withCurrency(item.item.pivot.final_price, currency, 'long') }}
                </p>
              </template>
            </v-data-table>
            <!--            <div class="discountCode mt-8">-->
            <!--              <v-text-field-->
            <!--                  outlined-->
            <!--                  @keypress.native.enter="confirmCouponCampaign"-->
            <!--                  v-model.trim="couponCode"-->
            <!--                  :messages="couponCodeMessage"-->
            <!--                  :error="couponCodeStatus == 'error'"-->
            <!--                  :success="couponCodeStatus == 'success'"-->
            <!--                  :label="$t('basket.discountCode')"-->
            <!--                  clearable-->
            <!--                  @click:clear="clearCouponCode"-->
            <!--                  class=""-->
            <!--                  color="#6d6d6d"-->
            <!--                  id="couponCodeInput"-->
            <!--                  style="border-radius: 15px !important;"-->
            <!--              >-->
            <!--                <template v-slot:append>-->
            <!--                  <v-btn class="elevation-0 white&#45;&#45;text m-" color="#6d6d6d"-->
            <!--                         style="top:-7px;border-radius: 10px !important;"-->
            <!--                         @click="confirmCouponCampaign"-->
            <!--                         id="submitCouponCode"-->
            <!--                  >-->
            <!--                    {{ $t('confirm') }}-->
            <!--                  </v-btn>-->
            <!--                </template>-->
            <!--              </v-text-field>-->
            <!--              &lt;!&ndash;              <div class="text-center" style="margin-top: -20px">&ndash;&gt;-->
            <!--              &lt;!&ndash;                <v-btn color="#4576d8" id="addGiftCodeBtnModal" text&ndash;&gt;-->
            <!--              &lt;!&ndash;                       @click="modalGiftCode.visibility  = !modalGiftCode.visibility">&ndash;&gt;-->
            <!--              &lt;!&ndash;                  {{ $t('basket.addGiftCode') }}&ndash;&gt;-->
            <!--              &lt;!&ndash;                </v-btn>&ndash;&gt;-->
            <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
            <!--            </div>-->
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <div class="FactorOverView">
            <div class="titleBasket">
              {{ $t('basket.factor') }}
            </div>
            <div class="divider"></div>
            <div class="factorData mt-10">
              <div class="factorItem">
                <div class="itemTitle">
                  {{ $t('basket.factor') }}
                </div>
                <div class="itemData">
                  {{ withCurrency(cart.totalPrice, cart.currency, 'long') }}
                </div>
              </div>
              <!--              <div class="factorItem mt-5 ">-->
              <!--                <div class="itemTitle " :class="cart.sumDiscount > 0 && 'activeItem'">-->
              <!--                  {{ $t('basket.off') }}-->
              <!--                </div>-->
              <!--                <div class="itemData " :class="cart.sumDiscount > 0 && 'activeItem'">-->
              <!--                  {{ withCurrency(cart.sumDiscount, cart.currency, 'long') }}-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="factorItem walletArea mt-5" v-if="wallet.visibility">-->
              <!--                <div class="itemTitle">-->
              <!--                  <div class="d-flex">-->
              <!--                    <div>-->
              <!--                      {{ $t('basket.wallet') }}-->
              <!--                    </div>-->
              <!--                    <div>-->
              <!--                      <v-switch-->
              <!--                          id="switchWallet"-->
              <!--                          inset-->
              <!--                          v-model="wallet.status"-->
              <!--                          :readonly="wallet.readonly"-->
              <!--                          color="#46b1a1"-->
              <!--                          dense-->
              <!--                          style="display: inline-block;margin-top: -5px"-->
              <!--                          class="mx-1"-->
              <!--                      ></v-switch>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="itemData">-->
              <!--                  {{ withCurrency(profile.affiliate_wallet[cart.currency].balance, cart.currency, 'long') }}-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <hr class="mt-3" style="background: #b9b9b9">
            <div class="finalAmount">
              <div class="titleFinalAmount">
                {{ $t('basket.finalPrice') }}
              </div>
              <div class="dataFinalAmount">
                {{
                  withCurrency(finalPrice(this.cart.finalPrice, profile.affiliate_wallet[cart.currency].balance), cart.currency, 'long')
                }}
              </div>
            </div>
            <div class="text-center mt-15 d-flex justify-space-between">
              <template v-if="cart.currency != 'rial'">
                <div>
                  <img src="../../assets/Shopify_logo_2018.svg.png" width="50px" alt="">
                </div>
                <div>
                  <img src="../../assets/apple_pay.png" width="50px" alt="">
                </div>
                <div>
                  <img src="../../assets/G-Pay.jpg" width="50px" alt="">
                </div>
                <div>
                  <img src="../../assets/master_card.webp" width="25px" alt="">
                </div>
                <div>
                  <img src="../../assets/visa.jpg" width="50px" alt="">
                </div>
              </template>
            </div>
            <div>
              <div id="payBtn">
                <v-btn class="payBtn" :disabled="isPayDisable" :id="payBtnId" color="#46b1a1" elevation="0" block
                       @click="saveorder(cart,wallet)">
                  {{ $t('basket.onlinePay') }}
                </v-btn>
                <v-btn class="offlinePayBtn" outlined :disabled="isPayDisable" :id="payBtnId" color="#46b1a1"
                       elevation="0" block
                       @click="showOfflinePaymentDialog = true">
                  {{ $t('basket.offlinePay') }}
                </v-btn>


                <v-dialog v-model="showOfflinePaymentDialog" max-width="800" scrollable>
                  <ClientOfflinePayment v-if="showOfflinePaymentDialog" @done="savingOfflineDone"
                                        :preorder="true"
                                        :cart="cart"
                                        :show="showOfflinePaymentDialog"
                                        @close="showOfflinePaymentDialog=false"
                  ></ClientOfflinePayment>
                </v-dialog>
                <!--                <v-btn class="payOfflineBtn mt-3" :id="payBtnId" color="#46b1a1" outlined block>-->
                <!--                  {{ $t('basket.offlinePay') }}-->
                <!--                </v-btn>-->
                <v-bottom-navigation class="elevation-0" color="red" fixed
                                     v-if="$vuetify.breakpoint.mdAndDown && $route.name == 'preorder'">
                  <v-btn :disabled="isPayDisable" style="color:white!important;font-size: 15px" :id="payBtnId"
                         color="#46b1a1" elevation="0"
                         block
                         class="payBtnBottomNavigation"
                         @click="saveorder(cart,wallet)">
                    {{ $t('basket.onlinePay') }}
                  </v-btn>
                </v-bottom-navigation>
              </div>
              <!--              <div id="continueBtn" v-else>-->
              <!--                <v-btn class="payBtn" :id="payBtnId" color="#46b1a1" elevation="0" block-->
              <!--                       @click="pre_session_counseling.visibility = true">-->
              <!--                  {{ $t('confirmAndContinue') }}-->
              <!--                </v-btn>-->
              <!--                <v-bottom-navigation class="elevation-0" color="red" fixed v-if="$vuetify.breakpoint.mdAndDown && $route.name == 'preorder'">-->
              <!--                  <v-btn style="color:white!important;font-size: 15px" :id="payBtnId" color="#46b1a1" elevation="0"-->
              <!--                         block-->
              <!--                         @click="pre_session_counseling.visibility = true">-->
              <!--                    {{ $t('confirmAndContinue') }}-->
              <!--                  </v-btn>-->
              <!--                </v-bottom-navigation>-->
              <!--              </div>-->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";
import ModalGiftCode from "@/components/GiftCode/ModalGiftCode";
import EventsMixins from "@/mixins/EventsMixins";
import ContinueModalBasket from "@/components/Basket/ContinueModalBasket";
import CartMixin from "@/mixins/CartMixin";
import ClientOfflinePayment from "@/components/Basket/ClientOfflinePayment";

/**
 *
 * <h1>Cart Component</h1>  <p>this component contain <b>cart</b> functionality</p>
 *
 * @vue-event {} Mounted - Mounted get cart info and then prepare cart for data-table
 *
 */
export default {
  components: {ClientOfflinePayment, ContinueModalBasket, ModalGiftCode},
  mixins: [EventsMixins, CartMixin],
  /**
   * Called when the component is created.
   * @returns {void}
   */
  created() {
    // method logic here
  },
  data() {
    return {
      tirgunCode: "tirgan",
      isPayDisable: false,
      modalGiftCode: {
        visibility: false
      },
      showOfflinePaymentDialog: false,
      headers: [
        {text: this.$t('basket.datatable.title'), value: 'title', sortable: false, align: 'start'},
        {text: this.$t('basket.datatable.qty'), value: 'qty', sortable: false, align: 'center'},
        {text: this.$t('basket.datatable.price'), value: 'amount', sortable: false, align: 'center'},
        {text: this.$t('basket.datatable.totalAmount'), value: 'total_amount', sortable: false, align: 'center'},
      ],

      pre_session_counseling: {
        visibility: false,
      },
      couponCodeMessage: null,
      couponCodeStatus: null,
      products: [],
      couponCode: null,
    }
  },
  mounted() {
    if (!this.cart) {
      this.getCart()
    } else {
      this.prepareDataForComponent()
    }
  },
  computed: {
    ...mapGetters({
      cart: 'getCart',
      currency: 'getUserCurrency',
      profile: 'getProfile'
    }),
    // finalPrice(final) {
    //   let finalPrice = final
    //   if (this.wallet.status && !this.wallet.readonly && this.profile.affiliate_wallet[this.cart.currency].balance > 0)
    //     return this.cart.finalPrice - this.profile.affiliate_wallet[this.cart.currency].balance
    //   else if (this.profile.affiliate_wallet[this.cart.currency].balance < 0) {
    //     return this.cart.finalPrice + Math.abs(this.profile.affiliate_wallet[this.cart.currency].balance)
    //   } else
    //     return this.cart.finalPrice
    // }
  },

  methods: {
    addToCart(id, callback = () => {
    }) {
      this.requesting('cart', 'addToCart', '', {
        addone: true,
        package_id: id,
      }).then(resp => {
        this.replaceCart(resp.data[0])
        callback()
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    confirmCouponCampaign() {
      if (this.couponCode && this.couponCode == this.tirgunCode) {
        // if (this.)
        let promises = []
        Object.keys(this.cart.items).forEach((key) => {
          promises.push(this.removeItemPromise(this.cart.items[key]))
        })
        this.$emitEvent('setLoading', true)
        Promise.all(promises).then(() => {
          this.addToCart(290, () => {
            this.$emitEvent('notify', {title: 'کد تخفیف اعمال شد', color: 'green'})
          })
        })
      } else {
        this.couponCodeMessage = this.$t('notify.notFoundCoupon')
        this.couponCodeStatus = 'error'
      }
    },
    removeItemPromise(item) {
      // this.loading = true;
      return new Promise(((resolve, reject) => {
        this.requesting('cart', 'itemRemoveAll', '', {
          package_id: item.item.id
        }).then(() => {
          resolve(true)
        }).catch(() => {
          reject(false)
        })
      }))

    },

    savingOfflineDone() {
      this.showOfflinePaymentDialog = false;
      let message = this.$t('Notify.successfullyWithRedirect');
      setTimeout(() => {
        this.$router.push({name: "dashboard"})
      }, 2000)
      this.$emitEvent('notify', {title: message, color: 'green', id: 'ga_offline_payment',});
    },
    /**
     * This is Function for delete negative notation in negative numbers
     *
     * @param {number} input Input negative number
     *
     * @return {number}
     * */
    absNum(input) {
      return Math.abs(input);
    },

    /**
     * This is Function for increase quantity item in cart
     *
     * @param {object} item Item must be record of target product that user want increase quantity
     *
     * @param {object} item.item   Item is product info that user want increase quantity
     *
     *
     * */
    increaseQuantity(item) {
      this.$emitEvent('setLoading', true)
      this.requesting('cart', 'addToCart', '', {
        addone: false,
        package_id: item.item.id,
      }).then((resp) => {
        if (resp.data.hasOwnProperty('status')) {
          this.$emitEvent('notify', {title: this.$t('inBasketError'), color: 'red'})
        }
        this.GTMAddToCart(item)
        this.WebengageAddToCart(item)
        this.replaceCart(resp.data[0])

      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },

    /**
     * This is Function for decrease quantity item in cart
     *
     * @param {object} item Item must be record of target product that user want decrease quantity
     *
     * @param {object} item.item   Item is product info that user want decrease quantity
     *
     *
     * */
    decreaseQuantity(item) {
      this.$emitEvent('setLoading', true)
      this.requesting('cart', 'removeFromCart', '', {
        package_id: item.item.id,
      }).then((resp) => {
        this.GTMRemoveFromCart(item)
        this.replaceCart(resp.data[0])
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },

    /**
     * This is CalledFunction for called after submit gift code and notify user
     *
     *
     * */
    afterSubmitCode() {
      this.modalGiftCode = false
      this.$emitEvent('notify', {title: this.$t('notify.giftCodeSubmit'), color: 'green'})
    },

    /**
     * This function for clear coupon code from cart and use it in coupon code text filed in CartComponent:61
     *
     * */
    clearCouponCode() {
      if (this.couponCode) {
        this.$emitEvent('setLoading', true)
        this.requesting('cart', 'validateCoupon', '', {
          couponCode: ""
        }).then((resp) => {
          this.couponCodeMessage = null
          this.couponCodeStatus = null
          this.$emitEvent('notify', {
            title: this.$t('notify.deleteCouponCode'),
            color: 'green',
            id: 'clear_coupon_notify'
          })
          this.replaceCart(resp.data.cart)
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },

    /**
     * this is Function For get cart from backend with /v1/api/cart. after that  generate cart with "prepareBasketProduct" Function ,
     *
     * prepare wallet status with "prepareWalletUsed" , check coupon code that used in cart
     *
     * and if in url have "couponcode" query , submit coupon code in cart if coupon code is valid with "confirmCoupon" Function
     *
     * */
    getCart() {
      this.$emitEvent('setLoading', true)
      this.requesting('cart', 'getCart').then((resp) => {
        this.prepareDataForComponent()
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },

    prepareDataForComponent(couponCode = null) {
      this.couponCode = couponCode
      if (this.couponCode) {
        this.couponCodeStatus = 'success'
      }
      this.prepareBasketProduct()
      // this.prepareWalletUsed(this.cart.currency)
      if (this.$route.query.couponcode) {
        this.couponCode = this.$route.query.couponcode
        this.confirmCoupon()
      }
    },


    /**
     * this Function for prepare cart for render in data table
     *
     * @see getCart
     *
     *
     * */

    prepareBasketProduct() {
      if (this.cart) {
        this.products = []
        Object.keys(this.cart.items).forEach((item) => {
          this.products.push(this.cart.items[item])
        })
      }
    },

    /**
     * this Function for submit Coupon code in cart.  if coupon code is valid notify success to use otherwise notify warning to user
     *
     * */

    confirmCoupon() {
      if (this.couponCode) {
        this.$emitEvent('setLoading', true)
        this.requesting('cart', 'validateCoupon', '', {
          couponCode: this.couponCode
        }).then((resp) => {
          if (resp.data.status == 'invalid') {
            this.couponCodeMessage = this.$t('notify.notFoundCoupon')
            this.couponCodeStatus = 'error'
            this.CouponCodeWebengageTrack(this.couponCode, 'failed')
          } else {
            this.couponCodeMessage = this.$t('notify.couponCodeSubmit')
            this.couponCodeStatus = 'success'
            this.CouponCodeWebengageTrack(this.couponCode, 'success')
            this.replaceCart(resp.data.cart)
          }
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      } else {
        this.couponCodeMessage = this.$t('notify.enterCouponCode')
        this.couponCodeStatus = 'error'
      }
    },

    /**
     * When use some api like increase or decrease quantity or submit coupon code, backend return cart data and it must be replace with current cart to render updated cart.
     *
     * this Function replacing from current cart to new cart and store new data in vuex for other component
     *
     * */
    replaceCart(cart) {
      let payload = {
        key: 'cart',
        data: [
          cart
        ]
      }
      this.$store.dispatch('setCart', payload)
      this.prepareBasketProduct()
    },

    /**
     * this Function for close factor and redirect user to payment gateway. saveorder function called v1/api/saveorder api and get payment gateway link.
     *
     * payloads  of this api are wallet usage status and submited (validate) coupon code.
     *
     * after that send user payment and cart info to webengage and GTM.
     *
     * @param {object} cart - Cart Info
     *
     * @param {object} wallet - Wallet status
     *
     * */
    saveorder(cart, wallet) {
      let payload = {
        applyWallet: wallet.status,
        couponCode: cart.couponCode,
      }
      this.$emitEvent('setLoading', true)
      this.GTMCheckoutEvents(cart)
      this.WebengageProceedToPayment()
      this.requesting('cart', 'saveorder', '', payload).then((resp) => {
        if (resp.data.hasOwnProperty('url')) {
          this.isPayDisable = true
          this.$nextTick().then(() => {
            if (resp.data.url.includes('revolut')) {
              setTimeout(() => {
                  this.$router.push('/process-payment?wait=10000')
              },1000)
              window.open(resp.data.url, '_blank');
            } else {
              window.location = resp.data.url;
            }
          });
        }

      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },

  },
  /**
   * this is Vue Watcher for profile , if profile changed, this watcher called "prepareWalletUsed" to prepare wallet status
   *
   */
  watch: {
    'profile'() {
      if (this.cart) {
        // this.prepareWalletUsed(this.cart.currency)
      }
      this.getCart()
    }
  }
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 600px) {
  .basketItems {
    border: none !important;
    padding: 0px !important;
  }

  .discountCode {
    width: 100% !important;
  }

  .qtyArea {
    width: 70px !important;
    height: 30px !important;
    font-size: 14px !important;
  }
}

.discountCode {
  width: 60%;
  margin: 0px auto;
}

.basketItems {
  border-radius: 10px;
  border: solid 1px rgb(219, 219, 219);
  background-color: rgb(255, 255, 255);
  padding: 40px 20px;
  height: 100%;
}

.activeItem {
  color: #47c8bd !important;

}

.titleBasket {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.divider {
  width: 100px;
  height: 2px;
  background: #000000;
  margin: 20px auto;
}

.qtyArea {
  border: solid 1px rgb(219, 219, 219);
  padding-top: 3px;
  border-radius: 10px;
  text-align: center;
  width: 55px;
  height: 25px;
  font-size: 12px;
  cursor: pointer;
}

.FactorOverView {
  border-radius: 10px;
  border: solid 1px rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  padding: 40px 20px;
}

.factorItem {
  display: flex;
  justify-content: space-between;
  /*margin-bottom: 20px;*/
}

.itemTitle {
  font-size: 12px;
  color: #6d6d6d;
}

.itemData {
  font-size: 12px;
  color: #6d6d6d;
}

.finalAmount {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.titleFinalAmount {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: rgb(0, 0, 0);
}

.dataFinalAmount {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: rgb(0, 0, 0);
}

.payBtn {
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px;
  color: white !important;
  margin-top: 10px;
}


.payOfflineBtn {
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px;
}

.offlinePayBtn {
  height: 50px !important;
  border-radius: 10px !important;
  font-size: 14px;
  margin-top: 10px;
}
</style>