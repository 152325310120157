<template>
  <div class="fill-height">
    <AuthWindow @afterLogin="afterLogin"/>
  </div>
</template>

<script>
import AuthWindow from "@/components/Auth/AuthWindow";

export default {
  components: {AuthWindow},
  methods: {
    afterLogin() {
      this.$router.push({name: "dashboard"})
    }
  },
  mounted(){
  }
}
</script>

<style scoped>

</style>