<template>
  <div>
    <v-dialog v-model="modalGiftCode.visibility" width="400px">
      <v-card style="border-radius: 15px;"  id="modalGiftCode">
        <v-card-text class="pt-5">
          <div class="titleModal">
            {{$t('giftCode.title')}}
          </div>
          <div class="mt-10 formArea">
            <v-form ref="giftCodeForm" @keypress.enter="submitGiftCode" @submit.prevent="submitGiftCode">
              <v-text-field id="giftCodeTextFiled" v-model="giftCode" :rules="requiredRules" dense :label="$t('giftCode.placeholder')"></v-text-field>
            </v-form>
          </div>
          <div class="actionArea mt-5 text-center">
            <v-btn :disabled="loadingStatus" class="confirmBtn white--text" id="submitGiftCode" color="#46b1a1" elevation="0"
                   @click="submitGiftCode">
              تاییـــد
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modalGiftCode: Object
  },
  data() {
    return {
      giftCode: null,
    }
  },
  methods: {
    submitGiftCode() {
      if (this.$refs.giftCodeForm.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('wallet', 'submitGiftCode', '', {
          giftCode: this.giftCode
        }).then(() => {
          this.requesting('auth', 'getProfile').then(() => {
            this.$emit('afterSubmitCode')
          }).finally(() => {
            this.$emitEvent('setLoading', false)
          })
        }).catch(() => {
          this.$emitEvent('setLoading', false)
        }).finally(() => {
          this.$refs.giftCodeForm.reset()
        })
      }
    }
  }
}
</script>

<style scoped>
.titleModal {
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0);
}

.formArea {
  width: 85%;
  margin: 0px auto;
}

.confirmBtn {
  width: 178px !important;
  height: 45px !important;
  border-radius: 10px;
  font-size: 16px;
}
</style>