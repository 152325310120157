<template>
    <div class="alertstransition">
        <v-alert type="error" dense v-if="Object.keys(errors).length" class="alertstransition">
            <template v-for="(keyErrors) in errors">
                <v-fade-transition :key="keyErrors">
                    <v-list-item
                            v-for="err in keyErrors" :key="err" dense
                    >
                      <v-list-item-content dense>
                        <template v-if="$vuetify.rtl">
                          {{ err }}
                        </template>
                        <template v-else>
                          {{ err | enErrorHandler }}
                        </template>
                      </v-list-item-content>
                    </v-list-item>
                </v-fade-transition>

            </template>
        </v-alert>
    </div>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';

    export default {
        methods: {
            translateError(error) {
                return this.$vuetify.lang.t('errorCode_' + error);
            }
        },
        computed: {
            ...mapGetters({
                errors: 'getErrors'
            })
        },
        filters:{
          enErrorHandler(input){
            switch (input){
              case "وارد کردن مبلغ پرداختی با ارز مبدا الزامیست":
                return "It is necessary to enter the payment amount in the currency"
              case "کد رهگیری قبلا وارد شده است":
                return "The tracking code has already been entered"
              default:
                return input
            }
          }
        }

    }
</script>

<style scoped>
    .alertstransition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }
</style>