<template>
  <nav>
    <v-app-bar  class="elevation-0 " style="  background-color: rgb(248, 249, 251);">
      <template>
        <router-link :to="{name:'login',params:{lang:checkLang}}" style="text-decoration: none">
          <v-toolbar-title class="">
            <v-img :src="getPlatformInfo('logo')" max-width="70"></v-img>
          </v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        <template v-if="isLoggedin">
          <div class="text-center">
            <v-menu
                v-if="getUserInfo!=null"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn class="elevation-0 transparent mx-5" id="avatarPreorder" fab>
                  <v-avatar size="40">
                    <img v-on="on" width="50" src='./../../assets/temp/person.svg'/>
                  </v-avatar>
                </v-btn>
              </template>

              <v-card id="avatarMenu">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-if="getUserInfo">{{
                          getUserInfo.firstname + ' ' +
                          getUserInfo.lastname
                        }}
                      </v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn text rounded :to="{name:'setting'}">
                        {{ $t('showAccount') }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>

                    <v-list-item-title>
                      <v-btn text rounded @click="logout">
                        {{ $t('logout') }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-card>
            </v-menu>
          </div>
        </template>
        <!--                <v-spacer></v-spacer>-->
        <!--<div v-else>-->
        <!--<v-btn text class="mx-3" small :to="{name:'login',params:{lang:'fa'}}" dark>-->
        <!--<span class="black&#45;&#45;text">-->
        <!--{{$vuetify.lang.t('$vuetify.loginLabel')}} | {{$vuetify.lang.t('$vuetify.signUpLabel')}}-->
        <!--</span>-->
        <!--</v-btn>-->
        <!--</div>-->

      </template>
    </v-app-bar>

  </nav>
</template>
<script>
// import WordpressNavItems from '@/components/navs/WordpressNavItems'
import {mapGetters} from 'vuex';

export default {
  props: ['isSimia'],
  components: {
    // WordpressNavItems,
  },
  data() {
    return {

      walletMenu: false,
      path: this.$route.name,
      drawer: false,
    };
  },
  computed: {
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    },
    ...mapGetters(['getUserInfo', 'getCart', 'getPlatform']),
    showNavIcon() {
      // return true;
      return this.$vuetify.breakpoint.smAndDown;
    },

    showTopMenu() {
      return this.$vuetify.breakpoint.mdAndUp;
    },

    isRTL() {
      return this.$store.getters.isRTL;
    },
    walletGlobalItems() {

      return this.getUserInfo.wallet.global_packages;
    },
    totalWalletItems() {

      return this.getUserInfo.wallet.total;
    },
    walletDoctorItems() {
      return this.getUserInfo.wallet.dr_packages;
    },
    cartItems() {
      return this.$store.getters.cartQuantity;

    },
    isLoggedin() {
      return this.$store.getters.userIsLoggedIn;
    },
  },
  created() {
    // this.chooseLogo();
    // console.log(this.$store.state.userInfo);
  },
  watch: {},
  methods: {}
}
</script>
<style>
.myBadge {
  position: absolute;
  top: -8px;
  right: -5px;
  background-color: red;
  width: 17px;
  height: 17px;
  line-height: 19px;
  text-align: center;
  border-radius: 30px;
  color: white;
}
</style>