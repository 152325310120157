<template>
  <div>
    <v-dialog v-model="pre_session_counseling.visibility" :width="$vuetify.breakpoint.mdAndUp ? '700px' : '100%'">
      <v-card :class="$vuetify.breakpoint.mdAndUp && 'pa-5'">
        <v-card-title class="justify-center">
          <!--          <div class=" text-center">-->
          <!--            {{ $t('CartComponent.PreSessionModal.title') }}-->
          <!--          </div>-->
          <img src="../../assets/simialogopng.png" class="mt-3" width="180px" alt="">
        </v-card-title>
        <v-card-text>
          <div class="preSessionText">
            <template v-if="profile">
              <template v-if="profile.leadUser">
                <p class="mt-5 preSessionPar">
                  {{ $t('CartComponent.PreSessionModal.text.leadUser[0]') }}
                </p>
                <p class="preSessionPar">
                  {{ $t('CartComponent.PreSessionModal.text.leadUser[1]') }}
                </p>
              </template>
              <template v-else>
                <p class="mt-8 preSessionPar text-center">
                  {{ $t('CartComponent.PreSessionModal.text.oldUser[0]') }}
                </p>
              </template>
            </template>
            <template v-else>
              <div class="text-center mt-10">
                <v-progress-circular indeterminate></v-progress-circular>
              </div>
            </template>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center pb-5">
          <template v-if="profile">
            <template v-if="profile.leadUser">
              <div style="width: 50%;margin: 0px auto">
                <v-btn class=" white--text"
                       :to="{name:'dashboard'}"
                       id="confirmBtn"
                       large
                       block
                       style="position:relative;background: #3CBE8B;font-size: 13pt" elevation="0">
                  {{ $t('CartComponent.PreSessionModal.action') }}
                </v-btn>
              </div>
            </template>
            <template v-else>
              <a href="https://wa.me/00989302672054"
                 style="width: 50%!important;text-decoration: none">
                <v-btn class=" white--text"
                       id="confirmBtn"
                       large
                       style="position:relative;background: #3CBE8B;margin: 0px auto;width: 100% !important;font-size: 9pt"
                       elevation="0">
                  <v-icon small class="mx-1">mdi-whatsapp</v-icon>
                  {{ $t('CartComponent.PreSessionModal.text.oldUser[1]') }}
                </v-btn>
              </a>
            </template>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    pre_session_counseling: Object,
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile'
    })
  }
}
</script>

<style scoped>

</style>