export default {
    methods: {
        prepareWalletUsed(currency) {
            if (this.profile && currency){
                if (this.profile.affiliate_wallet[currency].balance == 0) {
                    this.wallet.status = false
                    this.wallet.readonly = true
                    this.wallet.visibility = false
                } else if (this.profile.affiliate_wallet[currency].balance < 0) {
                    this.wallet.status = true
                    this.wallet.readonly = true
                    this.wallet.visibility = true
                } else {
                    this.wallet.status = true
                    this.wallet.readonly = false
                    this.wallet.visibility = true
                }
            }
        },
        finalPrice(final, walletBalance) {
            let finalPrice = final
            if (this.wallet.status && !this.wallet.readonly && walletBalance > 0) {
                let amount = finalPrice - walletBalance
                if (amount <= 0) {
                    return 0
                } else {
                    return amount
                }
            } else if (walletBalance < 0) {
                return finalPrice + Math.abs(walletBalance)
            } else
                return finalPrice
        }
    },
    computed: {},
    data() {
        return {
            wallet: {
                readonly: false,
                status: false,
                visibility: false,
            },
        }
    },
    watch: {
        'cart'() {
            this.prepareWalletUsed()
        }
    }
}